import React from "react";

function base_subscription() {
  if ("window" in global) {
    window.location.href = "/base_subscription.pdf";
  }
  return <p>redirect</p>;
}

export default base_subscription;
